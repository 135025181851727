// cell renderer class
export function EditableCellRenderer() {}
export function EditableCurrencyCellRenderer() {}
export function EditableNumberCellRenderer() {}
export function EditableBooleanCellRenderer() {}
export function EditableCheckboxCellRenderer() {}
export function WeeklyPromoCellRenderer() {}
export function EventFeeCellRenderer() {}

// init method gets the details of the cell to be rendere
EditableCellRenderer.prototype.init = function(params) {
  this.params = params;
  this.eGui = document.createElement("div");
  var text = "";
  if (params.value !== null) {
    if (params.value.name !== null && params.value.name !== undefined) {
      text = params.value.name;
    } else if (typeof params.value === "object" && Object.entries(params.value).length > 0) {
      text = "";
    } else {
      text = params.value;
    }
  }

  this.eGui.className = "editable";

  this.eGui.innerHTML = text;
};
// EditableCellRenderer.prototype.onBlur = function() {
//   const newValue = this.eGui.innerText;
//   if (newValue !== this.params.value) {
//     this.params.setValue(newValue);
//   }
// };

EditableCellRenderer.prototype.getGui = function() {
  return this.eGui;
};

EditableCurrencyCellRenderer.prototype.init = function(params) {
  this.eGui = document.createElement("div");
  var text = "";
  if (Math.sign(params.data.id) !== -1) {
    text = params.valueFormatted;
    this.eGui.className = "editable-numeric";
  }
  this.eGui.innerHTML = text;
};

EditableCurrencyCellRenderer.prototype.getGui = function() {
  return this.eGui;
};

EditableNumberCellRenderer.prototype.init = function(params) {
  this.eGui = document.createElement("div");
  var text = "";

  text = params.valueFormatted;

  this.eGui.className = "editable-numeric";

  this.eGui.innerHTML = text;
};

EditableNumberCellRenderer.prototype.getGui = function() {
  return this.eGui;
};

EditableBooleanCellRenderer.prototype.init = function(params) {
  this.eGui = document.createElement("div");
  var text = "";

  text = params.value;

  this.eGui.className = "editable-boolean";

  this.eGui.innerHTML = text;
};

EditableBooleanCellRenderer.prototype.getGui = function() {
  return this.eGui;
};

EditableCheckboxCellRenderer.prototype.init = function(params) {
  params.colDef.editable = false;
  this.eGui = document.createElement("input", "checked");
  this.eGui.type = "checkbox";
  if (params.value) {
    this.eGui.checked = 1;
  } else {
    this.eGui.checked = 0;
    //this.eGui.removeAttribute("checked");
  }
  this.eGui.addEventListener("click", function(event) {
    if (params.value === 0) {
      params.value = 1;
    } else {
      params.value = 0;
    }
  });
  // return this.eGui;
};
EditableCheckboxCellRenderer.prototype.getGui = function() {
  return this.eGui;
};

WeeklyPromoCellRenderer.prototype.init = function(params) {
  this.eGui = document.createElement("div");
  var text = "Weekly Settings";

  this.eGui.innerHTML = text;
};
WeeklyPromoCellRenderer.prototype.getGui = function() {
  return this.eGui;
};
EventFeeCellRenderer.prototype.init = function(params) {
  this.eGui = document.createElement("div");
  var text = "Set Event Fees";
  this.eGui.onclick = () => {
    console.log(params);
  };
  this.eGui.innerHTML = text;
};
EventFeeCellRenderer.prototype.getGui = function() {
  return this.eGui;
};
