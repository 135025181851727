import React, { Component } from "react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import "ag-grid-community";
import "../style/agGrid.css";
import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";

LicenseManager.setLicenseKey(
  "Using_this_{AG_Grid}_Enterprise_key_{AG-065063}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{TELUS_Agriculture_Solutions_Inc.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{TABSInsight}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{TABSInsight}_need_to_be_licensed___{TABSInsight}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{14_October_2025}____[v3]_[01]_MTc2MDM5NjQwMDAwMA==07fb06b515a537484f5c2ab1d7f57d4b"
);
// import {
//   POS_UNITS,
//   CUME_OVER_UNDER,
//   INCREMENTAL_LIFT_UNITS,
//   SHIP_UNITS
// } from "../constants";
//import PercChangeModal from "./percChangeModal";

export function checkForImmutableCell(_this, editable, curRow) {
  //Do not edit cells that are non editable
  if (editable === undefined) {
    return false;
  }
  return !editable;
}

export function changeByPercent() {
  //show change by % modal
  this.handleToggleModal();
}
export function increaseXPercent(percent) {
  if (this.gridApi.rangeController.isEmpty()) {
    return;
  }

  var _this = this;
  var rangeSelections = this.gridApi.rangeController.getCellRanges();
  var beginRow = rangeSelections[0].startRow;
  var beginRowIdx = beginRow.rowIndex;
  var endRow = rangeSelections[0].endRow;
  var endRowIdx = endRow.rowIndex;
  let curRowIdx = beginRowIdx;

  rangeSelections[0].columns.forEach((value, index) => {
    while (curRowIdx <= endRowIdx) {
      let colId = value.colId;
      if (checkForImmutableCell(this, value.pinned, beginRowIdx)) {
        //do Nothing
      } else {
        let fillValue = parseFloat(_this.gridApi.getRowNode(curRowIdx).data[value.colId], 10);
        _this.gridApi.getRowNode(curRowIdx).setDataValue(colId, fillValue + fillValue * parseFloat(percent, 10));
      }
      curRowIdx = curRowIdx + 1;
    }
    curRowIdx = beginRowIdx;
  });
}

// export function fillDown() {
//   if (this.gridApi.rangeController.isEmpty()) {
//     return;
//   }
//   var rangeSelections = this.gridApi.rangeController.getCellRanges();
//   var beginRow = rangeSelections[0].start;
//   var beginRowIdx = beginRow.rowIndex;
//   // var endRow = rangeSelections[0].end;
//   // var endRowIdx = endRow.rowIndex;
//   var endRow = this.gridApi.rowModel.rowsToDisplay.length;

//   var curRow = beginRowIdx;

//   var fillValue = this.gridApi.rowModel.rowsToDisplay[beginRowIdx].data[
//     beginRow.column.colDef.field
//   ];
//   if (endRow - curRow < 1000) {
//     while (curRow < endRow) {
//       if (
//         checkForImmutableCell(this, beginRow.column.colDef.editable, curRow)
//       ) {
//         //do Nothing
//       } else {
//         this.gridApi
//           .getRowNode(this.gridApi.rowModel.rowsToDisplay[curRow].id)
//           .setDataValue(rangeSelections[0].columns[0].colId, fillValue);
//       }
//       curRow = curRow + 1;
//     }
//   }
//   //console.log(selectedArr);
// }

export function fillAcross() {
  if (this.gridApi.rangeController.isEmpty()) {
    return;
  }
  var _this = this;
  var rangeSelections = this.gridApi.rangeController.getCellRanges();
  var beginRow = rangeSelections[0].startRow;
  var beginRowIdx = beginRow.rowIndex;
  var endRow = rangeSelections[0].endRow;
  var endRowIdx = endRow.rowIndex;
  var fillValue = this.gridApi.rowModel.rowsToDisplay[beginRowIdx].data[rangeSelections[0].startColumn.colDef.field];

  if (beginRowIdx === endRowIdx) {
    var totColumns = this.gridApi.columnController.gridColumns;
    let start = false;
    totColumns.forEach(value => {
      if (rangeSelections[0].startColumn.colDef.colId.toString() === value.colId) start = true;
      if (start) {
        if (checkForImmutableCell(this, value.colDef.editable, beginRowIdx)) {
          //do Nothing
        } else {
          if (typeof fillValue === "object" && value.colDef.cellRenderer !== "FKCellRenderer") {
            fillValue = fillValue.name;
          }
          _this.gridApi.getRowNode(_this.gridApi.rowModel.rowsToDisplay[beginRowIdx].id).setDataValue(value.colId, fillValue);
        }
      }
    });
  }
}
export function excelExport() {
  function formattingFunction(params) {
    if (params.column.colDef.cellRenderer === undefined) {
      return params.value;
    } else if (params.column.colDef.cellRenderer === "FKCellRenderer") {
      if (params.value === null) {
        return "NULL";
      } else if (params.value.name === undefined) {
        return params.value;
      } else {
        return params.value.name;
      }
    } else {
      return params.value;
    }
  }
  let excelParams = {
    processCellCallback: formattingFunction
  };
  this.gridApi.exportDataAsExcel(excelParams);
}
export function fillSelection() {
  if (this.gridApi.rangeController.isEmpty()) {
    return;
  }
  var _this = this;
  var rangeSelections = this.gridApi.rangeController.getCellRanges();
  var beginRow = rangeSelections[0].startRow;
  var beginRowIdx = beginRow.rowIndex;
  var endRow = rangeSelections[0].endRow;
  var endRowIdx = endRow.rowIndex;
  let curRowIdx = beginRowIdx;
  var fillValue = this.gridApi.rowModel.rowsToDisplay[beginRowIdx].data[rangeSelections[0].startColumn.colDef.field];

  rangeSelections[0].columns.forEach((value, index) => {
    while (curRowIdx <= endRowIdx) {
      let colId = value.colId;
      if (checkForImmutableCell(this, value.colDef.editable, beginRowIdx)) {
        //do Nothing
      } else {
        if (typeof fillValue === "object" && value.colDef.cellRenderer !== "FKCellRenderer") {
          fillValue = fillValue.name;
        }
        _this.gridApi.getRowNode(_this.gridApi.rowModel.rowsToDisplay[curRowIdx].id).setDataValue(colId, fillValue);
      }
      curRowIdx = curRowIdx + 1;
    }
    curRowIdx = beginRowIdx;
  });
}
export function fillDown() {
  if (this.gridApi.rangeController.isEmpty()) {
    return;
  }

  var beginRow;
  var rangeSelections = this.gridApi.rangeController.getCellRanges();
  if (rangeSelections[0].start === undefined) {
    beginRow = rangeSelections[0].startRow;
    beginRow.column = rangeSelections[0].startColumn;
  } else {
    beginRow = rangeSelections[0].start;
  }

  var beginRowIdx = beginRow.rowIndex;
  // var endRow = rangeSelections[0].end;
  // var endRowIdx = endRow.rowIndex;
  var endRow = this.gridApi.rowModel.rowsToDisplay.length;

  var curRow = beginRowIdx;
  var fillValue;
  if (this.gridApi.getRowNode(beginRowIdx) === undefined) {
    fillValue = parseFloat(this.gridApi.rowModel.rowsToDisplay[beginRowIdx].data[beginRow.column.colDef.field], 10);
  } else {
    fillValue = parseFloat(this.gridApi.getRowNode(beginRowIdx).data[beginRow.column.colId], 10);
  }

  while (curRow < endRow) {
    if (checkForImmutableCell(this, beginRow.column.colDef.pinned, curRow)) {
      //do Nothing
    } else {
      if (this.gridApi.getRowNode(curRow) === undefined) {
        this.gridApi.rowModel.rowsToDisplay[curRow].setDataValue(beginRow.column.colId, fillValue);
      } else {
        this.gridApi.getRowNode(curRow).setDataValue(rangeSelections[0].columns[0].colId, fillValue);
      }
    }
    curRow = curRow + 1;
  }
  //console.log(selectedArr);
}
export class GlobalAgGrid extends Component {
  constructor(props) {
    super(props);
    this.fillAcross = fillAcross;
    // this.fillDown = fillDown;
    this.increaseXPercent = increaseXPercent;
    this.fillSelection = fillSelection;
    this.excelExport = excelExport;
    this.changeByPercent = changeByPercent;
    //this.handleToggleModal = this.handleToggleModal.bind(this);
    this.state = {
      //showModal: false
    };
    this.agGrid = React.createRef();
  }
  // Handle the visibility of the modal.
  // If `state.showModal` is false, sets it to true,
  // if is true, sets it to false.
  // handleToggleModal() {
  //   this.setState({ showModal: !this.state.showModal });
  // }
  handleValue(value) {
    this.increaseXPercent(value / 100);
    //this.setState({ showModal: !this.state.showModal });
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.context = { thisComponent: this };
    this.gridColumnApi = params.columnApi;
    this.props.gridReady(this.agGrid);
    //this.gridApi.sizeColumnsToFit();

    // console.log("onGridReady");
    // var datasource = {
    //   getRows: params => {
    //     this.info =
    //       "Getting datasource rows, start: " +
    //       params.startRow +
    //       ", end: " +
    //       params.endRow;

    //     this.props.getRowData(params.startRow, params.endRow).then(result => {
    //       params.successCallback(result);
    //     });
    //   }
    // };
    // params.api.setDatasource(datasource);
    //this.props.gridReady(params);
  }
  checkRemove() {
    let selectedNodes = this.gridApi.getSelectedNodes().map(node => {
      return { rowId: node.rowIndex, data: node.data };
    });

    this.props.removeRows(selectedNodes);
  }
  checkRemoveStatus() {
    let selectedNodes = this.gridApi.getSelectedNodes().map(node => {
      return { rowId: node.rowIndex, data: node.data };
    });
    if (selectedNodes.length > 0) {
      return true;
    }
    return false;
  }
  duplicateRow = params => {
    if (params.node) {
      const newData = { ...params.node.data };
      delete newData.ID;
      newData.crudType = "insert";
      const currentIndex = params.node.rowIndex;

      if (this.props.onDuplicateRow) {
        this.props.onDuplicateRow(newData, currentIndex + 1);

        // Wait for the grid to update and then ensure the new row is visible
        setTimeout(() => {
          this.gridApi.ensureIndexVisible(currentIndex + 1, "middle");
        }, 0);
      }
    }
  };
  getContextMenuItems = params => {
    var result = [
      // {
      //   // custom item
      //   name: "Fill Down ",
      //   action: function() {
      //     params.context.thisComponent.fillDown();
      //   },
      //   cssClasses: ["redFont", "bold"]
      // },
      {
        // custom item
        name: "Fill Across ",
        action: function() {
          params.context.thisComponent.fillAcross();
        },
        cssClasses: ["redFont", "bold"]
      },
      {
        // custom item
        name: "Fill Selection ",
        action: function() {
          params.context.thisComponent.fillSelection();
        },
        cssClasses: ["redFont", "bold"]
      },
      {
        name: "Duplicate Row",
        action: () => {
          this.duplicateRow(params);
        },
        cssClasses: ["redFont", "bold"]
      },
      "separator",

      // built in copy item
      "copy",
      {
        // custom item
        name: "Excel Export (.xlsx)",
        action: function() {
          params.context.thisComponent.excelExport();
        }
      }
    ];

    return result;
  };
  getMainMenuItems(params) {
    //remove the tool panel from the menu
    let finalMenuItem = [];
    let itemsToExclude = "toolPanel";
    params.defaultItems.forEach(function(item) {
      if (itemsToExclude.indexOf(item) < 0) {
        finalMenuItem.push(item);
      }
    });
    return finalMenuItem;
  }

  render() {
    //const { showModal } = this.state;
    return (
      <div
        className="ag-theme-balham"
        style={{
          height: "100%",
          width: "100%"
        }}>
        <AgGridReact
          ref={this.agGrid}
          enableSorting={true}
          enableFilter={true}
          enableColResize={true}
          enableRangeSelection={true}
          // headerHeight={50}
          rowSelection={"multiple"}
          rowDeselection={true}
          //refreshCells={true}
          //suppressContextMenu={true}
          toolPanelSuppressSideButtons={true}
          getMainMenuItems={this.getMainMenuItems}
          //singleClickEdit={true}
          context={{ thisComponent: this }}
          getContextMenuItems={this.getContextMenuItems}
          //allowContextMenuWithControlKey={false}
          onGridReady={this.onGridReady.bind(this)}
          suppressCopyRowsToClipboard={true}
          processCellForClipboard={function(params) {
            if (params.value === null) {
              return null;
            } else if (typeof params.value === "object") {
              return params.value.name;
            } else {
              return params.value;
            }
          }}
          processCellFromClipboard={params => {
            if (params.column.colDef.cellRenderer === "FKCellRenderer") {
              let value = params.column.colDef.cellEditorParams.values.filter(item => {
                return item.name === params.value;
              });
              if (value[0] === undefined) {
                return { name: params.value, status: "invalid" };
              }
              return value[0];
            } else if (typeof params.value === "object") {
              return params.value.name;
            } else {
              return params.value;
            }
          }}
          {...this.props}
        />
      </div>
    );
  }
}
