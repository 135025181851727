import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  addTabToNav,
  setSelectedMdhApp,
  removeTabFromNav,
  setSelectedTab
} from "components/topLevelNav/reducers/topLevelNavReducer";
import "./style/style.css";
import { filterApps, setReportGroup, setCustomGroup } from "reducers/appReducer";
import { CloseIcon } from "lib/icons";

class MDMItem extends Component {
  constructor(props) {
    super(props);
    this.clickItemHandler = this.clickItemHandler.bind(this);
    this.closeMdhItem = this.closeMdhItem.bind(this);
    this.goTo = this.goTo.bind(this);
  }
  clickItemHandler(event, appIndex) {
    const { mdmApps } = this.props;
    let currAppIdx = parseInt(appIndex, 10);
    event.stopPropagation();
    this.props.filterApps("", this.props.apps);

    this.props.history.push("/mdm/dataManagement/" + currAppIdx + "+0");
    let findIdx = 0;
    mdmApps.forEach((item, idx) => {
      if (item.ID === parseInt(currAppIdx, 10)) {
        findIdx = idx;
      }
    });
    this.props.setSelectedMdhApp(currAppIdx);
    this.props.addTabToNav({
      title: this.props.mdmApps[findIdx].APP_NAME,
      mdmIdx: findIdx,
      appId: currAppIdx,
      id: currAppIdx + "+0+0",
      path: "/mdm/dataManagement/" + currAppIdx + "+0",
      type: "mdh",
      icon: "mdm"
    });
    this.props.handleClickOutside();
  }
  goTo(route) {
    this.props.handleClickOutside();
    this.props.filterApps("", this.props.apps);
    this.props.history.push(route);
  }
  closeMdhItem(e, item) {
    e.preventDefault();
    e.stopPropagation();
    this.props.removeTabFromNav(item);

    if (this.props.tabs.length >= 2) {
      let tabLocation = 1;
      this.props.tabs.forEach((tab, idx) => {
        if (tab.type === "mdh") {
          if (item.ID === tab.appId) {
            tabLocation = idx;
          }
        }
      });
      if (tabLocation === 0 && this.props.tabs.length > 2) {
        this.props.setSelectedTab([1, 0]);
        if (this.props.tabs[1].path === undefined) {
          if (this.props.tabs[1].workspaceId !== undefined) {
            this.goTo("/reports/landing/" + this.props.tabs[1].workspaceId);
          }
        } else {
          this.goTo(this.props.tabs[1].path);
        }
      } else if (tabLocation === 0) {
        this.props.setSelectedTab([0, 0]);
        if (this.props.tabs[1].path === undefined) {
          if (this.props.tabs[1].workspaceId !== undefined) {
            this.goTo("/reports/landing/" + this.props.tabs[1].workspaceId);
          }
        } else {
          this.goTo(this.props.tabs[1].path);
        }
      } else {
        if (this.props.tabs[tabLocation - 1].path === undefined) {
          let ntWorkspaceId = this.props.tabs[tabLocation - 1].workspaceId;
          // let wksFlt = this.props.workspaces.filter(item => {
          //   return item.workspaceId === ntWorkspaceId;
          // });
          // wksFlt = wksFlt[0];
          this.goTo("/reports/landing/" + ntWorkspaceId);
          this.props.setReportGroup(ntWorkspaceId);
        } else {
          this.goTo(this.props.tabs[tabLocation - 1].path);
        }
        this.props.setSelectedTab([tabLocation - 1, 0]);
      }
    } else {
      this.props.setReportGroup("");
      this.props.setCustomGroup("");
      this.props.setSelectedTab([-1, -1]);

      this.goTo("/reports/landing");
    }
  }
  renderMdmItems(mdmApps) {
    const { mdhTabs } = this.props;
    let itemIsActive, itemIsSelected;

    if (mdmApps.groups !== undefined && mdmApps.groups.length > 0) {
      return mdmApps.groups.map((app, appIndex) => {
        if (mdhTabs.length === 0) {
          itemIsActive = undefined;
          itemIsSelected = false;
        } else {
          itemIsActive = mdhTabs.find(item => {
            return item.appId === app.ID;
          });
          itemIsSelected = mdhTabs.find(item => {
            return app.ID === item.appId;
          });
        }
        if (itemIsActive !== undefined) {
          app.index = itemIsActive.index[0];
          return (
            <div
              className="ta-report-name"
              key={appIndex}
              style={{
                fontStyle: "italic",
                paddingRight: "5px",
                display: "flex",
                flexDirection: "row"
              }}
              onClick={event => this.clickItemHandler(event, app.ID)}>
              {app.APP_NAME}
              <div
                onClick={e => {
                  this.closeMdhItem(e, app);
                }}
                className="close-x-subtitle">
                <CloseIcon height={"10px"} viewBox={"0 0 20 20"} className="close-icon" />
              </div>
            </div>
          );
        } else if (itemIsSelected) {
          return (
            <div
              className="ta-report-name"
              style={{ display: "flex", flexDirection: "row" }}
              key={appIndex}
              onClick={event => this.clickItemHandler(event, app.ID)}>
              {app.APP_NAME}
              <div
                onClick={e => {
                  this.closeMdhItem(e, app);
                }}
                className="close-x-subtitle">
                <CloseIcon height={"10px"} viewBox={"0 0 20 20"} className="close-icon" />
              </div>
            </div>
          );
        } else {
          return (
            <div className="ta-report-name" key={appIndex} onClick={event => this.clickItemHandler(event, app.ID)}>
              {app.APP_NAME}
            </div>
          );
        }
      });
    } else {
      return null;
    }

    //return appArr;
  }
  render() {
    const { mdmApp } = this.props;
    return <div className="">{this.renderMdmItems(mdmApp)}</div>;
  }
}

const mapStateToProps = state => ({
  mdmApps: state.mdm.mdmApps,
  apps: state.app.apps,
  selectedMdhApp: state.topLevelNav.selectedMdhApp,
  tabs: state.topLevelNav.tabs,
  mdhTabs: state.topLevelNav.mdhTabs
});
const mapDispatchToProps = dispatch => ({
  addTabToNav(tab) {
    dispatch(addTabToNav(tab));
  },
  filterApps(filter, apps) {
    dispatch(filterApps(filter, apps));
  },
  removeTabFromNav(tab) {
    dispatch(removeTabFromNav(tab));
  },
  setSelectedMdhApp(app) {
    dispatch(setSelectedMdhApp(app));
  },
  setSelectedTab(tab) {
    dispatch(setSelectedTab(tab));
  },
  setCustomGroup(group) {
    dispatch(setCustomGroup(group));
  },
  setReportGroup(group) {
    dispatch(setReportGroup(group));
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MDMItem));
